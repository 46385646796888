export default class NotificationsHubAuthorizer {

  constructor({
    centralizedAuth,
    env = "int"
  }) {
    this.centralizedAuth = centralizedAuth;
    this.subscriptionsUrl = `https://${getPrefix(env)}subscriptions.commerce.cimpress.io`;
    this.centralUrl = `https://${getPrefix(env)}central.mex.cimpress.io`;
  }

  ensureAuthorized({
    returnUri = window.location.href
  } = {}) {
    return this.didUserAuthorize()
      .then(authorized => {
        if (!authorized) {
          window.location.assign(`${this.centralUrl}/subscriptions/authorize?returnUri=${returnUri}`);
        }
        return Promise.resolve(authorized);
      });
  }

  didUserAuthorize() {
    const principal = this.centralizedAuth.getProfile()["https://claims.cimpress.io/email"] || this.centralizedAuth.getProfile().sub;
    return fetch(`${this.subscriptionsUrl}/v1/users/${principal}`, {
      method: "GET",
      mode: "cors",
      headers: new Headers({
        Accept: "application/json",
        Authorization: `Bearer ${this.centralizedAuth.getAccessToken()}`
      })
    }).then(res => {
      if (res.ok) {
        return true;
      }

      if (res.status === 404) {
        return false;
      }

      return this.handleError("Error retrieving user", res);
    });
  }

  handleError(message, response) {
    return response.json()
      .then(body => {
        const err = new Error(`${message}: (${response.status}) ${response.statusText}`);
        err.cause = body;
        throw err;
      });
  }
}

function getPrefix(env) {
  return env === "prd" || env === "production" ? "" : "int-";
}
