import React from 'react';
import PropTypes from 'prop-types';
import icons from '@cimpress-technology/react-streamline-icons/lib';

const NavItem = ({ item, active, nested, onClick }) => {
  const IconComponent = item.iconComponent ? icons[item.iconComponent] : undefined;
  return (
    <a
      onClick={onClick}
      href={item.url}
      className={`list-group-item clickable${active ? ' active' : ''}${nested ? ' level-two' : ''}`}>
      {IconComponent ? (
        <IconComponent />
      ) : item.glyphicon ? (
        <i className={`fa fa-${item.glyphicon}`} />
      ) : item.iconUrl ? (
        <img src={item.iconUrl} />
      ) : null}
      {item.text}
      {item.subItems && <i className={`fa fa-xl fa-angle-${active ? 'up' : 'down'} pull-right`} />}
    </a>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
  nested: PropTypes.bool,
  onClick: PropTypes.func,
};

export default NavItem;
