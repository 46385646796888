import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconPictureSun from '@cimpress-technology/react-streamline-icons/lib/IconPictureSun';
import Spinner from '@cimpress/react-components/lib/shapes/Spinner';
import isEmpty from 'lodash/isEmpty';

import { getLoaderSize } from '../helper';

class PreviewSection extends PureComponent {
  onImageLoaded = () => {
    this.props.onImageLoaded(this.props.imageUrl);
  }

  preloadImage = (props) => {
    const { imageUrl } = props;
    const img = new Image();
    img.id = imageUrl;
    img.onload = this.onImageLoaded;
    img.onerror = this.onImageLoaded;
    img.src = imageUrl;
  }

  imageSection() {
    const Image = this.renderImage;
    const NoImage = this.renderNoImage;
    const Loading = this.renderLoading;
    const imageClass = !isEmpty(this.props.imageClassName)
      ? this.props.imageClassName
      : 'image';

    if (this.props.loading) {
      return <Loading size={getLoaderSize(this.props.size)} imageClass={imageClass} />;
    }

    if (this.props.imageUrl) {
      return <Image imageClass={imageClass} imageUrl={this.props.imageUrl} />;
    }
    return <NoImage imageClass={imageClass} />;
  }

  renderImage(props) {
    return (
      <div
        className={`imageContainer ${props.imageClass}`}
        style={{ backgroundImage: `url(${props.imageUrl})` }}
      />
    );
  }

  renderNoImage(props) {
    return (
      <div className={`imageContainer noImage ${props.imageClass}`}>
        <IconPictureSun size="3x" color="grey" />
      </div>
    );
  }

  renderLoading(props) {
    return (
      <div className={`imageContainer noImage ${props.imageClass}`}>
        <Spinner size={props.size} />
      </div>
    );
  }

  render() {
    const {
      allowImageClick, onClick, hyperlink, loading,
    } = this.props;
    const anchorProps = allowImageClick && !loading ? {
      role: 'button', tabIndex: '0', onClick, href: hyperlink,
    } : {};

    if (this.props.preloadImage) {
      this.preloadImage(this.props);
    }

    return (
      <div className="previewArea">
        <a className="imageLink" {...anchorProps}>
          {this.imageSection()}
        </a>
      </div>
    );
  }
}

PreviewSection.propTypes = {
  imageUrl: PropTypes.string,
  imageClassName: PropTypes.string,
  allowImageClick: PropTypes.bool,
  onClick: PropTypes.func,
  hyperlink: PropTypes.string,
  loading: PropTypes.bool,
  size: PropTypes.string,
  onImageLoaded: PropTypes.func,
  preloadImage: PropTypes.bool,
  actions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  })),
};

PreviewSection.defaultProps = {
  imageUrl: '',
  imageClassName: '',
  allowImageClick: false,
  onClick: null,
  hyperlink: null,
  loading: false,
  size: 'l',
  onImageLoaded: () => null,
  preloadImage: false,
  actions: [],
};

export default PreviewSection;
